.bg {
    background:
        -webkit-gradient(
      linear,
      left top, left bottom,
      from(rgba(230, 230, 230, 0.65)),
      to(rgba(230, 230, 230, 0.65))
        ),
        url("../../assets/img/bg-1.jpg");
    background:
        linear-gradient(
      rgba(230, 230, 230, 0.65),
      rgba(230, 230, 230, 0.65)
        ),
        url("../../assets/img/bg-1.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
