body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.bg {
    background:
        -webkit-gradient(
      linear,
      left top, left bottom,
      from(rgba(230, 230, 230, 0.65)),
      to(rgba(230, 230, 230, 0.65))
        ),
        url(/static/media/bg-1.853777f6.jpg);
    background:
        linear-gradient(
      rgba(230, 230, 230, 0.65),
      rgba(230, 230, 230, 0.65)
        ),
        url(/static/media/bg-1.853777f6.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.slick-prev:before,
.slick-next:before {
  color: lightgray;
  font-size: 200%;
  opacity: 1;
  line-height: 0;
}
